@import './fonts';
@import './variables';
@import './bootstrap';

$chromecast-icon-size: 14px;

@mixin obe-gradient-bg {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff),
    linear-gradient(to right, #f4d7d3, #baf9ff 49%, #dfd9fe);
  background-repeat: no-repeat;
  background-size: 100% 50vh;
}

:root {
  --site-header-height: 64px;
  --site-header-initial-height: 64px;
  --sub-header-height: 66px;
  --sub-header-mobile-height: 45px;
  --recs-banner-height: 65px;
  --recs-banner-mobile-height: 78px;
  --font-family-base: centra, sans-serif;
  --black: #000000;
  --charcoal: #9b9b9b;
  --coral: #f07aa8;
  --eastside: #c79ccd;
  --grey: #dbdbdb;
  --green: #2e8b01;
  --feedback200: #e5341d;
  --ice: #f9f9fc;
  --lavender: #e3dcff;
  --lightBlue: #ceeaff;
  --lightCyan: #e4fdfe;
  --lightGrey: #f8f8f8;
  --lightMediumGrey: #dee2e6;
  --mediumGrey: #979797;
  --mediumPink: #f17aa8;
  --persianBlue: #622aff;
  --pink: #ff00ab;
  --steel: #c7c7c7;
  --yellow: #ffff01;
  --white: #ffffff;
  --nearBlack: #3a3f49;
  --neutrals100: #ffffff;
  --neutrals200: #fbfbfb;
  --neutrals300: #f8f8f8;
  --neutrals400: #e3e3e4;
  --neutrals500: #c2c1c5;
  --neutrals600: #908f94;
  --neutrals700: #4d4c4f;
  --neutrals800: #000000;
  --primary400: #622aff;
  --primary500: #5329c9;
  --gradientDark500: linear-gradient(#2f85ff, #9fc6ff);
  --gradientDark700: linear-gradient(#ff6d3c, #f02baf);
  --gradientLight300: linear-gradient(0, #adffc0 0%, #f6f6f6 100%);
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body > img {
  display: none;
}

.body-gradient {
  @include obe-gradient-bg;
}

.font-light {
  font-weight: 300;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.vjs-icon-broadcast {
  .vjs-icon-placeholder {
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMSAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsPSJ3aGl0ZSIgZD0iTTE4Ljg4ODkgMC41SDEuODg4ODlDMC44NSAwLjUgMCAxLjM1IDAgMi4zODg4OVYxMy43MjIyQzAgMTQuNzYxMSAwLjg1IDE1LjYxMTEgMS44ODg4OSAxNS42MTExSDYuNjExMTFWMTcuNUgxNC4xNjY3VjE1LjYxMTFIMTguODg4OUMxOS45Mjc4IDE1LjYxMTEgMjAuNzY4MyAxNC43NjExIDIwLjc2ODMgMTMuNzIyMkwyMC43Nzc4IDIuMzg4ODlDMjAuNzc3OCAxLjM1IDE5LjkyNzggMC41IDE4Ljg4ODkgMC41Wk0xOC44ODg5IDEzLjcyMjJIMS44ODg4OVYyLjM4ODg5SDE4Ljg4ODlWMTMuNzIyMloiIC8+PC9zdmc+')
      center center no-repeat;
    background-size: 35%;
    width: 100%;
    height: 100%;
    display: block;
  }
}

.vjs-control.vjs-button.vjs-icon-friends {
  position: absolute;
  right: 0;
  width: fit-content;
  margin-right: 16px;
  font-family: $font-family-base !important;

  @include media-breakpoint-down(md) {
    margin-right: 10px;
  }
}

.vjs-chromecast-button {
  .vjs-icon-placeholder {
    background: url($icon-chromecast--default) center center no-repeat;
    background-size: contain;
    display: inline-block;
    width: $chromecast-icon-size;
    height: $chromecast-icon-size;
  }
  &:hover {
    cursor: pointer;
    .vjs-icon-placeholder {
      background-image: url($icon-chromecast--hover);
    }
  }
  &.vjs-chromecast-casting-state {
    .vjs-icon-placeholder {
      background-image: url($icon-chromecast-casting);
    }
    &:hover .vjs-icon-placeholder {
      background-image: url($icon-chromecast-casting--hover);
    }
  }
}

.vjs-tech-chromecast {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .vjs-tech-chromecast-poster {
    &::after {
      content: ' ';
      display: block;
      height: 2px;
      width: $chromecast-poster-width;
      background-color: $chromecast-color-main;
      position: absolute;
      left: calc(50% - #{$chromecast-poster-width / 2});
    }
  }
  .vjs-tech-chromecast-poster-img {
    max-height: $chromecast-poster-max-height;
    width: auto;
    border: 2px solid $chromecast-color-main;
    &.vjs-tech-chromecast-poster-img-empty {
      width: 160px;
      height: 90px;
    }
  }
  .vjs-tech-chromecast-title-container {
    position: absolute;
    bottom: 50%;
    margin-bottom: 100px;
    color: $chromecast-color-main;
    text-align: center;
  }
  .vjs-tech-chromecast-title {
    font-size: $chromecast-title-font-size;
    &.vjs-tech-chromecast-title-empty {
      display: none;
    }
  }
  .vjs-tech-chromecast-subtitle {
    font-size: $chromecast-subtitle-font-size;
    padding-top: 0.5em;
    &.vjs-tech-chromecast-subtitle-empty {
      display: none;
    }
  }
}

.player-chromecast-connected .vjs-tech-chromecast-poster {
  width: 100%;
  height: 100%;
}

.player-chromecast-connected .vjs-tech-chromecast .vjs-tech-chromecast-poster-img {
  width: 100%;
  height: 100%;
  max-height: unset;
  object-fit: cover;
  border: none;
}

// react-datepicker styles that should be applied sitewide
.react-datepicker {
  border: 1px solid #dbdbdb !important;
  font-family: $font-family-base !important;
}

.react-datepicker__day {
  color: #979797 !important;
}

.react-datepicker__day--disabled {
  color: #ccc !important;
}

.react-datepicker__triangle {
  border-bottom-color: #fff !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__time-list-item--selected {
  background-color: #622aff !important;
  color: #fff !important;
  font-weight: 600;
}

.react-datepicker__current-month {
  font-weight: 600 !important;
  text-transform: uppercase;
}

.react-datepicker__header {
  background-color: #fff !important;
}

.react-datepicker__year-dropdown-container--scroll {
  margin: 0 16px 0 2px !important;
}

.react-datepicker__year-dropdown--scrollable {
  color: #000;
  background: #fff !important;
}

.react-datepicker-time__header {
  text-transform: uppercase;
}

.react-datepicker__time {
  color: #878787 !important;
}

@include media-breakpoint-down(md) {
  .react-datepicker__month-container {
    width: 230px;
  }

  .react-datepicker__month {
    margin: 0 !important;
  }
}

// reactstrap modal styles that are used sitewide
.ModalComponent {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14);
  border: none;

  &.SearchModal {
    height: 600px;
    max-height: 600px;
  }
}
